import { type RegistrationConfiguration, useCurrentUser, useHookContext } from '@base-app/library'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { useId, useState } from 'react'
import type { CalendarItem } from './types'

const dateFormatter = new Intl.DateTimeFormat('da', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
})

export const config = {
    name: 'Example Integration',
    hooks: [
        {
            type: 'menu',
            id: 'example-page-menu',
            component: ExampleApp,
            title: 'Example Page',
            api: window.__injected_API_URL,
        },
    ],
} satisfies RegistrationConfiguration

function useHttpClient() {
    const { httpClient } = useHookContext()
    if (!httpClient) {
        throw new Error('HttpClient is not defined, it should be')
    }

    return httpClient
}

function useCalendar() {
    const httpClient = useHttpClient()

    const response = useSuspenseQuery({
        queryKey: ['calendar'],
        queryFn: async ({ signal }) => {
            return (await httpClient.get<CalendarItem[]>({ signal }, 'Calendar')).map((item, index) => ({
                ...item,
                id: index,
            }))
        },
        select: (items) => {
            return items.map(({ startTime, endTime, ...item }) => {
                return {
                    ...item,
                    startTime: new Date(startTime),
                    endTime: new Date(endTime),
                }
            })
        },
    })

    return response.data
}

export function ExampleAppContents() {
    const user = useCurrentUser()
    const [token, setToken] = useState<string | null>(null)

    const id = useId()

    const ip = useQuery({
        queryKey: ['ip'],
        queryFn: async ({ signal }) => {
            return user?.tokenClient.get<string>({ signal }, 'ip')
        },
    })

    const calendar = useCalendar()

    if (!user.isAuthenticated) {
        return <p>Login first</p>
    }

    return (
        <>
            <p>
                Hello, {ip.data} you are {user?.name ?? 'NO-USER'}{' '}
                {user ? (
                    <button
                        type="button"
                        onClick={user.onLogout}
                    >
                        Logout
                    </button>
                ) : null}
                {user?.getAccessToken ? (
                    <button
                        type="button"
                        onClick={async () => {
                            const token = await user?.getAccessToken?.()
                            if (token) {
                                setToken(token.accessToken)
                            }
                        }}
                    >
                        get access token
                    </button>
                ) : null}
                <label
                    id={id + '_label'}
                    htmlFor={id + '_token'}
                >
                    Token field
                </label>
                <textarea
                    id={id + '_token'}
                    aria-labelledby={id + '_label'}
                    value={token ?? ''}
                    style={{ width: '100% ' }}
                    rows={12}
                    readOnly
                />
            </p>
            <h2>DinPlads Calendar items</h2>
            <ul>
                {calendar.map((item) => (
                    <li key={item.id}>
                        <h2>{item.title}</h2>
                        <p>{item.description}</p>
                        <p title={`${item.startTime.toISOString()} - ${item.endTime.toISOString()}`}>
                            {dateFormatter.formatRange(item.startTime, item.endTime)}
                        </p>
                    </li>
                ))}
            </ul>
        </>
    )
}

export function ExampleApp() {
    return <ExampleAppContents />
}
